import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { numberFormat } from '../../../Global/numberFormat';
import LoadingSpinner from '../../../modules/Billing/Components/LoadingSpinner';


const FocusProductVsTargetProductRolling = ({ focusProductTarget, workingDays }) => {

    const dispatch = useDispatch();
    const { register, setValue } = useForm();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const regionDataFilter = [{}, {}];
    const currentDate = new Date();
    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };
    console.log('selectedMonth', selectedMonth)

    const divisionDataViews = useSelector(state => state?.mapData?.topTwentTSM?.allData);
    const divisionDataViewIsLoading = useSelector(state => state?.mapData?.topTwentTSMIsLoading);
    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];
    const monthNumber = focusProductTarget && focusProductTarget.length > 0 ? focusProductTarget[0]?.month : null;
    const monthName = monthNames[monthNumber - 1]; // Adjusting index since array is zero-based

    return (
        <div className='container ims-division-wise-report'>
            <div>
                <div className="dailysales      container-fluid top_card3-map rounded mb-5  p-2">
                    <h1 style={{ width: '47%', margin: 'auto' }}>Focus Product Target Vs Ach {monthName && "("} {monthName} {monthName && ")"}</h1>
                </div>
            </div>
            <div className="table-container">
                <table id="table-to-xls" className="territory-table">
                    <thead className='header-working'>
                        <th>  </th>
                        <th> Working days:  {workingDays?.totWorkingDay}</th>

                        <th> Working days Pass: {workingDays?.passWorkingDay}  </th>
                        <th>  </th>
                        <th></th>
                        <th> Need IMS: {workingDays?.imsTarget.toFixed(2)}  %</th>
                        <th></th>

                    </thead>
                    <thead className='forFontstyle'>
                        <tr>
                            <th> SI</th>
                            <th> Product Name</th>
                            <th>Target</th>
                            <th> Primary </th>
                            <th> IMS</th>
                            <th>Primary
                                Ach%</th>
                            <th>IMS Ach% </th>


                        </tr>
                    </thead>
                    <tbody className="territory-tr">
                        {focusProductTarget?.map((item, index) => (
                            <tr key={index}>
                                <td className='text-center-style heigtsetInHeader'>{index + 1}</td>
                                <td className='text-center-style heigtsetInHeader'>{item?.brandName}</td>
                                <td className='text-center-style heigtsetInHeader'>{numberFormat(item?.imsTarget)}</td>
                                <td className='text-center-style heigtsetInHeader'> {numberFormat(item?.primaryValue)}</td>
                                <td className='text-center-style heigtsetInHeader'>{numberFormat(item?.totImsCm)}</td>
                                <td className='text-center-style heigtsetInHeader'>{item?.primaryAchPer}%</td>
                                <td className='text-center-style heigtsetInHeader' > {item?.imsAchPer}%</td>

                            </tr>
                        ))}

                    </tbody>

                </table>
            </div>


        </div>
    );
};

export default FocusProductVsTargetProductRolling;