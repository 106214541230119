import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../modules/Billing/Components/LoadingSpinner';
import { numberFormat } from '../../../Global/numberFormat';


const TopsellingSkuRolling = ({ topSellingDatas, workingDays }) => {

    const dispatch = useDispatch();
    const { register, setValue } = useForm();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const regionDataFilter = [{}, {}];
    const currentDate = new Date();
    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };
    console.log('selectedMonth', selectedMonth)

    const divisionDataViews = useSelector(state => state?.mapData?.topSellingData?.allData);
    const divisionDataViewIsLoading = useSelector(state => state?.mapData?.topSellingDataIsLoading);
    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];
    const monthNumber = topSellingDatas && topSellingDatas.length > 0 ? topSellingDatas[0]?.month : null;
    const monthName = monthNames[monthNumber - 1]; 

    return (
        <div className='container ims-division-wise-report'>
            <>

                <div>
                    <div className="dailysales      container-fluid top_card3-map rounded mb-5  p-2">
                        <h1 style={{ width: '36%', margin: 'auto' }}>Top Selling SKU {monthName && "("} {monthName} {monthName && ")"}</h1>
                    </div>
                </div>
                <div className="table-container">
                    <table id="table-to-xls" className="territory-table">
                    <thead className='header-working'>
                            
                                <th> Working days:  {workingDays?.totWorkingDay}</th>
                           
                           
                                <th> Working days Pass: {workingDays?.passWorkingDay}  </th>
                         
                                <th> Need IMS: {workingDays?.imsTarget.toFixed(2)}  %</th>
                              

                            </thead>
                        <thead>
                            <tr>
                                <th> SI</th>
                                <th> SKU Name</th>
                                <th> IMS</th>
                            </tr>
                        </thead>
                        <tbody className="territory-tr">
                            {topSellingDatas?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item?.prodName}</td>
                                    <td > {numberFormat(item?.totImsCm)}</td>

                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>

            </>


        </div>
    );
};

export default TopsellingSkuRolling;