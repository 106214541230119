import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../modules/Billing/Components/LoadingSpinner';
import { numberFormat } from '../../../Global/numberFormat';

const BottomTenRmRolling = ({ bottomTenRmDatas, workingDays }) => {

    const dispatch = useDispatch();
    const { register, setValue } = useForm();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const regionDataFilter = [{}, {}];
    const currentDate = new Date();
    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };


    const divisionDataViews = useSelector(state => state?.mapData?.bottomThreeDsm?.allData);
    const divisionDataViewIsLoading = useSelector(state => state?.mapData?.bottomThreeDsmIsLOading);
    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];
    const monthNumber = bottomTenRmDatas && bottomTenRmDatas.length > 0 ? bottomTenRmDatas[0]?.month : null;
    const monthName = monthNames[monthNumber - 1]; // Adjusting index since array is zero-based

    return (
        <div className='container ims-division-wise-report'>
            <div>
                <div className="dailysales   container-fluid top_card3-map rounded mb-5  p-2">
                    <h1 style={{ width: '30%', margin: 'auto' }}>Bottom Ten RM {monthName && "("} {monthName} {monthName && ")"}</h1>
                </div>
            </div>

            {!divisionDataViewIsLoading && <>

                <div className="table-container">
                    <table id="table-to-xls" className="territory-table">
                        <thead className='header-working'>
                            <th>  </th>
                            <th> Working days:  {workingDays?.totWorkingDay}</th>
                            <th></th>
                            <th>  </th>
                            <th></th>
                            <th> Working days Pass: {workingDays?.passWorkingDay}  </th>
                            <th>  </th>
                            <th></th>
                            <th> Need IMS: {workingDays?.imsTarget.toFixed(2)}%</th>
                            <th></th>

                        </thead>
                        <thead className='forFontstyle'>
                            <tr>
                                <th> SI</th>
                                <th> Region Name</th>
                                <th>Total IMS Target</th>
                                <th>Total IMS <br /> {monthName}</th>
                                <th>IMS Ach%</th>
                                <th>IMS Landing %</th>
                                <th>SO Qty </th>
                                <th>IMS Growth% </th>
                                <th>Average
                                    Per So
                                    Per Day
                                    IMS </th>
                                <th>Ranking
                                    (tar Vs
                                    IMS
                                    Ach%)
                                </th>
                            </tr>
                        </thead>
                        <tbody className="territory-tr">
                            {bottomTenRmDatas?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item?.regionName}</td>
                                    <td>{numberFormat(item?.imsTarget)}</td>
                                    <td> {numberFormat(item?.totImsCm)}</td>
                                    <td> {item?.imsAchPer}%</td>
                                    <td className={` ${item?.imsLandingPer < 100 ? 'redColorStyle' : 'greenColorStyle'} text-center-style`} >{item?.imsLandingPer}%</td>
                                    <td>{item?.totSo}</td>
                                    <td className={` ${item?.imsGrowth < 1 ? 'redColorStyle' : null} text-center-style`} > {item?.imsGrowth}%</td>
                                    <td className='text-center-style'> {numberFormat(item?.perSoIms)}</td>
                                    <td className='text-center-style'>{item?.imsRanking}</td>
                                </tr>
                            ))}

                        </tbody>

                    </table>
                </div>
            </>

            }
        </div>
    );
};

export default BottomTenRmRolling;