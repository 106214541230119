import React from "react";
/* import territory from "../../Data/territory";
 */
import { useSelector } from "react-redux";
import { numberFormat } from "../../../Global/numberFormat";
import DownloadDropdown from "../../../modules/master/PrintDwopdown/ExcelDawnload";
import LoadingSpinner from "../../../modules/Billing/Components/LoadingSpinner";

const TopBiscutChanelModal = ({ onClose, filterId, topBiskutChanelData, id }) => {

  const territory = useSelector(state => state.mapData?.territoryDataForModal?.data?.allData)
  console.log('filterIdfilterId', topBiskutChanelData, filterId)


  const territoryFilterData = territory?.filter(
    (item) => item.id === parseInt(id)
  );

  const january = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.january);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;
  const february = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.february);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;
  const march = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.march);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;
  const april = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.april);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;
  const may = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.may);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;
  const june = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.june);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;

  const july = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.july);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;

  const aguest = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.aguest);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;

  const september = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.september);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;

  const october = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.october);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;

  const november = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.november);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;

  const december = filterId?.reduce((sum, zone) => {
    const salesValue = parseFloat(zone.december);
    return isNaN(salesValue) ? sum : sum + salesValue;
  }, 0) || 0;


  const getRowCount = (filterId, productName, currentIndex) => {
    let count = 1;
    for (let i = currentIndex + 1; i < filterId.length; i++) {
      if (filterId[i]?.productionlLineName === productName) {
        count++;
      } else {
        break;
      }
    }
    return count;
  };



   return (
    <div className="modal">
      <div className="modal-content">
        <div style={{ display: 'flex' }}>
          <div className="text-right col-lg-11" >
            <DownloadDropdown excelname={filterId && filterId[0]?.channel_Name} />
          </div>
          <div className="text-end">
            <span className="close closs-icon" onClick={onClose}>
              &times;
            </span>
          </div>
        </div>

        <h4 className="mb-3">
          {filterId && filterId[0]?.channel_Name}
        </h4>
        <div className="table-container">
          <table id="table-to-xls" className="territory-table">
            <thead>
              <tr>
                <th>Line Name</th>
                <th> Sales</th>
                <th>January</th>
                <th> February</th>
                <th> March</th>
                <th> April</th>
                <th> May</th>
                <th> June</th>
                <th> July</th>
                <th> Auguest</th>
                <th> September</th>
                <th> October</th>
                <th> November</th>
                <th> December</th>

              </tr>
            </thead>
            {
              filterId?.length < 0 && <LoadingSpinner />

            }
            <tbody className="territory-tr">
              {filterId?.map((item, index) => {
                const appearsMoreThanOnce = filterId?.filter(data => data?.productionlLineName === item?.productionlLineName)?.length > 1;
                const isFirstItem = index === 0;
                const isNewProduct = !isFirstItem && item?.productionlLineName !== filterId[index - 1]?.productionlLineName;

                return (
                  <tr key={index}>
                    {appearsMoreThanOnce && (isFirstItem || isNewProduct) && (
                      <td rowSpan={getRowCount(filterId, item?.productionlLineName, index)}>{item?.productionlLineName}</td>
                    )}
                    {!appearsMoreThanOnce && <td>{item.productionlLineName}</td>}
                    <td>{item.maintainTarget}</td>
                    <td>{item.january > 0 ? numberFormat(parseInt(item.january?.toFixed(2))) : ''} {(item.maintainTarget === "ACHIEVEMENT" && item.january>0) ? '%' :''}</td>
                    <td>{item.february > 0 ? numberFormat(parseInt(item.february?.toFixed(2))) : ''} {(item.maintainTarget === "ACHIEVEMENT" && item.february>0) ? '%' :''}</td>
                    <td>{item.march > 0 ? numberFormat(parseInt(item.march?.toFixed(2))) : ''}{(item.maintainTarget === "ACHIEVEMENT" && item.march>0) ? '%' :''}</td>
                    <td>{item.april > 0 ? numberFormat(parseInt(item.april?.toFixed(2))) : ''} {(item.maintainTarget === "ACHIEVEMENT" && item.april>0) ? '%' :''} </td>
                    <td>{item.may > 0 ? numberFormat(parseInt(item.may?.toFixed(2))) : ''} {(item.maintainTarget === "ACHIEVEMENT" && item.may>0) ? '%' :''} </td>
                    <td>{item.june > 0 ? numberFormat(parseInt(item.june?.toFixed(2))) : ''} {(item.maintainTarget === "ACHIEVEMENT" && item.june>0) ? '%' :''}</td>
                    <td>{item.july > 0 ? numberFormat(parseInt(item.july?.toFixed(2))) : ''} {(item.maintainTarget === "ACHIEVEMENT" && item.july>0) ? '%' :''}</td>
                    <td>{item.aguest > 0 ? numberFormat(parseInt(item.aguest?.toFixed(2))) : ''}{(item.maintainTarget === "ACHIEVEMENT" && item.aguest>0) ? '%' :''}</td>
                    <td>{item.september > 0 ? numberFormat(parseInt(item.september?.toFixed(2))) : ''}{(item.maintainTarget === "ACHIEVEMENT" && item.september>0) ? '%' :''}</td>
                    <td>{item.october > 0 ? numberFormat(parseInt(item.october?.toFixed(2))) : ''}{(item.maintainTarget === "ACHIEVEMENT" && item.october>0) ? '%' :''}</td>
                    <td>{item.november > 0 ? numberFormat(parseInt(item.november?.toFixed(2))) : ''} {(item.maintainTarget === "ACHIEVEMENT" && item.november>0) ? '%' :''}</td>
                    <td>{item.december > 0 ? numberFormat(parseInt(item.december?.toFixed(2))) : ''} {(item.maintainTarget === "ACHIEVEMENT" && item.december>0) ? '%' :''}</td>
                  </tr>
                );
              })}
            </tbody>
            <thead>
              <tr>
                <th> </th>
                <th>  Total  </th>
                <th>{numberFormat(parseInt(january)) || "00"}</th>
                <th>{numberFormat(parseInt(february)) || "00"}</th>
                <th>{numberFormat(parseInt(march)) || "00"}</th>
                <th>{numberFormat(parseInt(april)) || "00"}</th>
                <th>{numberFormat(parseInt(may)) || "00"}</th>
                <th>{numberFormat(parseInt(june)) || "00"}</th>
                <th>{numberFormat(parseInt(july)) || "00"}</th>
                <th>{numberFormat(parseInt(aguest)) || "00"}</th>
                <th>{numberFormat(parseInt(september)) || "00"}</th>
                <th>{numberFormat(parseInt(october)) || "00"}</th>
                <th>{numberFormat(parseInt(november)) || "00"}</th>
                <th>{numberFormat(parseInt(december)) || "00"}</th>

              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TopBiscutChanelModal;
